import React, { useState, useCallback } from 'react'
import { useTransition, animated } from 'react-spring'
import { Flex, Box, Heading } from '@theme-ui/components'
import Footer from '../components/footer';
import ReactMarkdown from 'react-markdown'

const collection = [
  {
    title: 'Groper',
    description: `Application for measuring response times of your web application 
    around the world and testing you design on multiple devices.`,
    thumbnail: require('../../static/groper.png')
  },
  {
    title: 'Serverlesscass',
    description: `The best place to learn everything related to serverless technology.
    From cloud provides, to productivity tools.`,
    thumbnail: require('../../static/servercast.png')
  }
];


function Project({ title, description, thumbnail }) {

  return ({ style }) => {
    return <animated.div style={{ ...style, width: '100%', height:'100%', top: 0, right: 0, position: 'absolute'}}> 
      <Flex sx={{ 
          justifyContent: 'center', 
          alignItems: 'center', 
          width: "100%", 
          height: "100%",
          flexDirection: ["row"],
          '@media screen and (min-width: 1000px)': {
            display: 'row',
          }
        }}>
        <Box sx={{ 
          width: ["100%", "50%"], 
          height: ["30%", "100%"], 
          justifyContent: 'center', 
          alignItems: 'center',
          display: 'none',
          '@media screen and (min-width: 1000px)': {
            display: 'flex',
          }
        }}>
          <img src={thumbnail} />
        </Box>
        <Flex sx={{ 
            width: ["100%", "50%"], 
            height: ["70%", "100%"], 
            justifyContent: ['flex-start', 'center'], 
            flexDirection: "column",  alignItems: 'center', padding: '2rem 1rem' 
        }}>
        
          <MobileContainer  title={title} description={description} />
          <ContentContainer title={title} description={description} />

        </Flex>
      </Flex>
    </animated.div>
  } 
}


function MobileContainer({ title, description }) {
  return (
    <animated.div style={{ width: '100%', height:'100%', top: 0, right: 0, position: 'absolute'}}> 
        <Box
          sx={{
            flexDirection: 'column',
            display: 'flex',
            '@media screen and (min-width: 1000px)': {
              display: 'none',
            }
          }}
        >
          <Heading className="card-header" as="h2" style={{ fontSize: ['5rem', '7rem'], textAlign: "center" }} py={4}>{ title }</Heading>
          <Box className="card-body" sx={{ 
              flexDirection: 'column',
          }}>
              <ReactMarkdown source={description}></ReactMarkdown>
          </Box>
        </Box>
    </animated.div>
  )
}

function ContentContainer({ title, description }) {
  return (
    <Box 
      className="card position cursor"
      sx={{ 
        flexDirection: 'column',
        display: 'none',
        '@media screen and (min-width: 1000px)': {
          display: 'flex',
        }
      }}
    >
      <Heading className="card-header" as="h2" style={{ fontSize: ['5rem', '7rem'] }} py={4}>{ title }</Heading>
      <Flex className="card-body" sx={{ 
          flexDirection: 'column',
      }}>
          <ReactMarkdown source={description}></ReactMarkdown>
      </Flex>
    </Box> 
  )
}

function TransitionCollection() {

  const pages = collection.map(Project);

  const [index, set] = useState(0)

  const onClick = useCallback(() => set(state => (state + 1) % 2), [])

  const transitions = useTransition(index, p => p, {
    from: { opacity: 0, transform: 'translateY(50%)' },
    enter: { opacity: 1, transform: 'translateY(0%)'},
    leave: { opacity: 0, transform: 'translateY(-50%)' },
  });

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column"
      }}
    >
      {transitions.map(({ item, props, key}, index) => {
        const Page = pages[item];

        // console.log(pages, Page, item);
        return ( Page ? <Page key={index} style={props} /> : null )
      })}
      <Flex
        sx={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          position: "absolute",
          bottom: 0,
          left: 0,
          zIndex: 999,
          paddingY: 2,
        }}
      
      >
        {pages.map((d, i) => {
          return (
            <Box key={`key-${i}`} className="circle" sx={{ padding: 2 }}>
                <button tabIndex="0" role="" aria-label={`Link to project ${i+1}`} onClick={() => set(i)}>{i}</button>
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}
  
export default ({ section }) => {


  return (
      <section id="root" className="project"> 
        <Flex 
          sx={{
            width: '100%',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <TransitionCollection collection={section} />
        </Flex>
        <Footer pageNumber={3} />
      </section>
  )
}