import React from 'react'
import { Flex, Heading, Text } from '@theme-ui/components'
import ReactMarkdown from 'react-markdown'
import Footer from '../components/footer';

const page = {
title: 'Blog',
description: `
[Nodejs debugging and performance 2](https://blog.gdo-studio.si/2021/nodejs_debuging_and_performance_two)

[Nodejs debugging and performance 1](https://blog.gdo-studio.si/2020/october/nodejs_debuging_and_performance)

[What are nodejs streams?](https://blog.gdo-studio.si/2020/august/what-are-node-streams/)

[JSCONF japan](https://blog.gdo-studio.si/2019/december/jsconf/)
`
};


export default () => {

    return (
        <section className="blog">
            <Flex sx={{ justifyContent: 'center', width: '100vw', height: '100vh', padding: 1 }}>
                <Flex sx={{ width: '100%', flexDirection: 'column', px: 4, pt: 5, alignItems: 'flex-start'}}>
                    <Heading py={2} as="h1" pb={4}>{ page.title }</Heading>
                    <Text sx={{ py: 3, lineHeight: 1.4 }} className="blog-link">
                        <ReactMarkdown source={page.description}> </ReactMarkdown>
                    </Text>
                </Flex>
                <Footer pageNumber={4} />
            </Flex>
        </section>
    )
}
