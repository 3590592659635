import React from 'react'
import { Flex, Heading, Text } from '@theme-ui/components'
import Footer from '../components/footer';
// import TypeGraphy from '../images/topography.svg';
import WordMap from '../images/word-map.svg';

const page = {
    title:'We craft the tools for the new generation',
    description: `
        We are a small company, with a mission of changing the web development game.

        Specialized in:

        -  web development
        -  serverless technology
        -  machine learning
    `
};

function Description({ title, texts }) {
    return (
        <Flex
            className="transition description-background"
            sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                position: 'relative',
                backgroundImage: `url(${WordMap})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: ['bottom', 'left center'],
                boxShadow: '5px 5px 15px 5px hsla(0,0%,0%,0.5), 5px 5px 15px -2px hsla(0,0%,0%,0.3)'
            }}
        >
            <Heading as="h2" style={{color: "white", fontSize: ['2rem', '5rem'] }} py={4}>{ title }</Heading>
            {
                texts.map((item, key) => {
                    return <Text key={"descripiton" + key} sx={{ fontSize: 4, pb: '0.5rem' }}>{ item }</Text>
                })
            }
            <Footer pageNumber={2} color="white" padding="0rem 3rem 1rem 3rem" />
        </Flex> 
    )
}

// add media query
// 2rem 1rem 2rem 2rem
export default () => {
    // create basic serializer

    const title = page.title;
    const bulletPoints = page.description.split('\n');

    const style = {
        padding: '0rem',
        '@media screen and (min-width: 1000px)': {
          padding: '2rem'
        },
        height: '100%',
    }; 

    return (
        <section className="flex-none description">
            <Flex sx={style}>
                <Description title={title} texts={bulletPoints}/>
            </Flex>
        </section>
    )
}