import React from "react"
import "../components/layout.css"
import './index.css';

import CookieConsent from 'react-cookie-consent';


import Layout from "../components/layout"
import SEO from "../components/seo"

// Section 
import Intro from '../section/intro' 
import Description from '../section/description'
import Project from '../section/projects' 
import Contact from '../section/contact' 
import Blog from '../section/blog' 


const IndexPage = () => {


  return (
    <Layout  >
      <SEO title="Home" />
        <Intro />
        <Description  />
        <Project      />
        <Blog         />
        <Contact      />
        <CookieConsent
          disableStyles={true}
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics"
          buttonClasses="cookie-button"
          containerClasses="cookie-container"
          contentClasses="cookie-content"
        >
         We are using cookies to give you the best experience on our site. Cookies are files stored in your browser and are used by most websites to help personalize your web experience. 
        </CookieConsent>
    </Layout>
  )
}

export default IndexPage
