import React from "react"

const Footer = ({ pageNumber = 1, color, padding = '0rem 2rem' }) => {
    return (
        <div style={{ 
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute', 
            bottom: 0, left: 0, 
            width: '100%', 
            padding: padding

        }}>
            <h5 style={{color: color}}>{ pageNumber }</h5>
        </div>
    )
}

export default Footer