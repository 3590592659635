import React from 'react'
import { Link } from 'gatsby';
import  IconMail from '../images/icon-mail.svg'
import IconPhone from '../images/icon-phone-ring.svg'
import { Flex, Heading, Label, Text, Box, Image } from '@theme-ui/components'
import Footer from '../components/footer';


function LinkForm({ title, subtitle, value, type="mailto", children }) {
    return (
        <Flex className="contact-link"> 
            <Flex sx={{justifyContent: 'center', pr: 2, py: 2 }}>
                {children}
            </Flex>
            <Flex sx={{flexDirection: 'column', justifyContent: 'center', py: 2}} >
                <Label htmlFor='username' sx={{fontWeight: "bold"}}>
                    <a href={`${type}:${value}`}>{title}</a>
                </Label>
                <Text sx={{ fontSize: '0.8rem', fontWeight: "bold" }}>{subtitle}</Text>
            </Flex> 
        </Flex>
    )
}


export default ({ section }) => {
    return (
        <section>
            <Flex className="contact" sx={{ justifyContent: 'center', width: '100vw', height: '100vh', padding: 1,  flexDirection: "column"}}>
                <Flex sx={{flexDirection: 'column', justifyContent: 'flex-start', width: '100%', height: '100%', pt: 5, px: 4}}>
                    <Heading py={2} as="h1">Want to talk?</Heading>
                    <Flex sx={{flexDirection: 'column'}}>
                        <LinkForm type="mailto" value={"business@gdo-studio.si"} title={"business@gdo-studio.si"} subtitle={"will reply in 24hrs"} >
                            <Box py={2} ><Image mt={2} mr={2} width={30} height={30} src={IconMail} /></Box>
                        </LinkForm>
                        <LinkForm type="tel" value={"38641859954"} title={"+386 418 59954"} subtitle={"9-5 pm mon-fri"} >
                            <Box py={2} ><Image mt={2} mr={2} width={30} height={30} src={IconPhone} /></Box>
                        </LinkForm>
                    </Flex>
                </Flex>
                <Flex sx={{ paddingBottom: 15, paddingLeft: 10, zIndex: 10}}>
                    <Link to="/terms">Terms</Link>
                </Flex>
            </Flex>
            <Footer pageNumber={"END"} />
        </section>
    )
}